var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-verification-wrapper"},[_vm._m(0),_c('div',{staticClass:"body"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"align-items-center p-5",attrs:{"lg":"5"}},[_c('div',{staticClass:"welcome-text"},[_vm._v(" "+_vm._s(_vm.$t('Welcome to the Norwegian Health Portal'))+" ")]),_c('img',{staticClass:"mt-3 w-100",attrs:{"src":require("@/assets/images/pages/auth_medical_care_movn.svg"),"alt":""}})]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"7"}},[_c('validation-observer',{ref:"verificationValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Do you have HPR number?'),"label-for":"is_doctor","label-class":"font-weight-bolder"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-form-radio',{staticClass:"mr-3",attrs:{"plain":"","name":"is_doctor","value":true},model:{value:(_vm.form.is_doctor),callback:function ($$v) {_vm.$set(_vm.form, "is_doctor", $$v)},expression:"form.is_doctor"}},[_vm._v(" "+_vm._s(_vm.$t('Yes'))+" ")]),_c('b-form-radio',{attrs:{"plain":"","name":"is_doctor","value":false},model:{value:(_vm.form.is_doctor),callback:function ($$v) {_vm.$set(_vm.form, "is_doctor", $$v)},expression:"form.is_doctor"}},[_vm._v(" "+_vm._s(_vm.$t('No'))+" ")])],1)]),(_vm.form.is_doctor)?_c('b-form-group',{attrs:{"label":_vm.$t('HPR - Number'),"label-for":"hpr-number"}},[_c('validation-provider',{attrs:{"name":"hpr_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"hpr-number","state":errors.length > 0 ? false:null,"name":"hpr_number","placeholder":_vm.$t('HPR - Number')},model:{value:(_vm.form.hrp_number),callback:function ($$v) {_vm.$set(_vm.form, "hrp_number", $$v)},expression:"form.hrp_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('div',[_vm._v(_vm._s(_vm.$t('You can find you HPR-number here'))+": "),_c('a',{attrs:{"href":"https://register.helsedirektoratet.no/hpr","target":"_blank"}},[_vm._v("Helsedirektoratets")])])]}}],null,false,3688462840)})],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Private mobile number'),"label-for":"private-number"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Private mobile number'),"rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"private-number","state":errors.length > 0 ? false:null,"name":"private_mobile","placeholder":_vm.$t('Private mobile number')},model:{value:(_vm.form.private_mobile),callback:function ($$v) {_vm.$set(_vm.form, "private_mobile", $$v)},expression:"form.private_mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? ((errors[0]) + ". " + (_vm.$t('Use +47'))) : ''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email","placeholder":_vm.$t('Email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[(!_vm.form.is_doctor)?_c('b-form-group',{attrs:{"label":_vm.$t('Working place'),"label-for":"working_place"}},[_c('validation-provider',{attrs:{"name":"working_place","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"working_place","state":errors.length > 0 ? false:null,"name":"working_place","placeholder":_vm.$t('Type your working place here...')},model:{value:(_vm.form.working_place),callback:function ($$v) {_vm.$set(_vm.form, "working_place", $$v)},expression:"form.working_place"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2980114418)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"12"}},[(!_vm.form.is_doctor)?_c('b-form-group',{attrs:{"label":_vm.$t('Reason to join'),"label-for":"reason_to_join"}},[_c('validation-provider',{attrs:{"name":"reason_to_join","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reason_to_join","state":errors.length > 0 ? false:null,"name":"reason_to_join","placeholder":_vm.$t('Type your reason here...')},model:{value:(_vm.form.reason_to_join),callback:function ($$v) {_vm.$set(_vm.form, "reason_to_join", $$v)},expression:"form.reason_to_join"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,489057428)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Password'),"label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","state":errors.length > 0 ? false:null,"name":"password","type":"password","placeholder":_vm.$t('Password')},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Confirm password'),"label-for":"password_confirmation"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Confirm password'),"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password_confirmation","state":errors.length > 0 ? false:null,"name":"password_confirmation","type":"password","placeholder":_vm.$t('Confirm password')},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',[_c('b-link',{attrs:{"target":"_blank","href":"https://beta.norskhelseportal.no/terms-and-privacy-policy"}},[_c('small',[_vm._v(_vm._s(_vm.$t('Terms of use and Privacy Policy')))])])],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"terms_and_conditions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"terms_and_conditions","state":errors.length > 0 ? false:null,"name":"terms_and_conditions"},model:{value:(_vm.form.terms_and_conditions),callback:function ($$v) {_vm.$set(_vm.form, "terms_and_conditions", $$v)},expression:"form.terms_and_conditions"}},[_vm._v(" "+_vm._s(_vm.$t('I accept the terms'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"confirmation","state":errors.length > 0 ? false:null,"name":"confirmation"},model:{value:(_vm.form.confirmation),callback:function ($$v) {_vm.$set(_vm.form, "confirmation", $$v)},expression:"form.confirmation"}},[_vm._v(" "+_vm._s(_vm.$t('I understand that I will not share any sensitive information on NHP or with other users'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"d-flex justify-content-center"},[_c('b-link',{staticClass:"mr-3",attrs:{"to":{name: 'home'}}},[_c('small',[_vm._v(_vm._s(_vm.$t('Back')))])]),_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" "+_vm._s(_vm.$t('Verify and register my account'))+" ")])],1)],1)],1)],1)],1)],1),_c('b-modal',{attrs:{"id":"modal-modal-term-privacy-policy","title":_vm.$t('Overflowing Content'),"cancel-variant":"outline-secondary","cancel-title":_vm.$t('Close'),"ok-title":_vm.$t('Accept')}},[_c('b-card-text',[_vm._v(" Some text ")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("@/assets/images/logo/norskhelse_logo.svg"),"alt":"logo"}})])}]

export { render, staticRenderFns }