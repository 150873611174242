<template>
  <div class="user-verification-wrapper">
    <div class="header">
      <img
        src="@/assets/images/logo/norskhelse_logo.svg"
        alt="logo"
      >
    </div>

    <div class="body">
      <b-row class="auth-inner m-0">

        <b-col
          lg="5"
          class="align-items-center p-5"
        >
          <div class="welcome-text">
            {{ $t('Welcome to the Norwegian Health Portal') }}
          </div>
          <img
            class="mt-3 w-100"
            src="@/assets/images/pages/auth_medical_care_movn.svg"
            alt=""
          >
        </b-col>

        <b-col
          lg="7"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >

          <validation-observer ref="verificationValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <b-form-group
                :label="$t('Do you have HPR number?')"
                label-for="is_doctor"
                label-class="font-weight-bolder"
              >

                <div class="d-flex justify-content-start">
                  <b-form-radio
                    v-model="form.is_doctor"
                    plain
                    name="is_doctor"
                    :value="true"
                    class="mr-3"
                  >
                    {{ $t('Yes') }}
                  </b-form-radio>

                  <b-form-radio
                    v-model="form.is_doctor"
                    plain
                    name="is_doctor"
                    :value="false"
                  >
                    {{ $t('No') }}
                  </b-form-radio>
                </div>

              </b-form-group>
              <b-form-group
                v-if="form.is_doctor"
                :label="$t('HPR - Number')"
                label-for="hpr-number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hpr_number"
                >
                  <b-form-input
                    id="hpr-number"
                    v-model="form.hrp_number"
                    :state="errors.length > 0 ? false:null"
                    name="hpr_number"
                    :placeholder="$t('HPR - Number')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <div>{{ $t('You can find you HPR-number here') }}: <a
                    href="https://register.helsedirektoratet.no/hpr"
                    target="_blank"
                  >Helsedirektoratets</a></div>
                </validation-provider>
              </b-form-group>

              <b-row>

                <b-col md="6">

                  <b-form-group
                    :label="$t('Private mobile number')"
                    label-for="private-number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Private mobile number')"
                      rules="required|phone"
                    >
                      <b-form-input
                        id="private-number"
                        v-model="form.private_mobile"
                        :state="errors.length > 0 ? false:null"
                        name="private_mobile"
                        :placeholder="$t('Private mobile number')"
                      />
                      <small class="text-danger">{{ errors[0] ? `${errors[0]}. ${$t('Use +47')}` : '' }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

                <b-col md="6">

                  <b-form-group
                    :label="$t('Email')"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        :state="errors.length > 0 ? false:null"
                        name="email"
                        :placeholder="$t('Email')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
                <b-col md="12">
                  <b-form-group
                    v-if="!form.is_doctor"
                    :label="$t('Working place')"
                    label-for="working_place"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="working_place"
                      rules="required"
                    >
                      <b-form-input
                        id="working_place"
                        v-model="form.working_place"
                        :state="errors.length > 0 ? false:null"
                        name="working_place"
                        :placeholder="$t('Type your working place here...')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    v-if="!form.is_doctor"
                    :label="$t('Reason to join')"
                    label-for="reason_to_join"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="reason_to_join"
                      rules="required"
                    >
                      <b-form-input
                        id="reason_to_join"
                        v-model="form.reason_to_join"
                        :state="errors.length > 0 ? false:null"
                        name="reason_to_join"
                        :placeholder="$t('Type your reason here...')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">

                  <b-form-group
                    :label="$t('Password')"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="password"
                      rules="required|min:8|password"
                    >
                      <b-form-input
                        id="password"
                        v-model="form.password"
                        :state="errors.length > 0 ? false:null"
                        name="password"
                        type="password"
                        :placeholder="$t('Password')"
                      />
                      <small class="text-danger">{{ $t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

                <b-col md="6">

                  <b-form-group
                    :label="$t('Confirm password')"
                    label-for="password_confirmation"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Confirm password')"
                      rules="required|confirmed:password"
                    >
                      <b-form-input
                        id="password_confirmation"
                        v-model="form.password_confirmation"
                        :state="errors.length > 0 ? false:null"
                        name="password_confirmation"
                        type="password"
                        :placeholder="$t('Confirm password')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

              </b-row>

              <b-form-group>
                <b-link
                  target="_blank"
                  href="https://beta.norskhelseportal.no/terms-and-privacy-policy"
                >
                  <small>{{ $t('Terms of use and Privacy Policy') }}</small>
                </b-link>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="terms_and_conditions"
                  rules="required"
                >
                  <b-form-checkbox
                    id="terms_and_conditions"
                    v-model="form.terms_and_conditions"
                    :state="errors.length > 0 ? false:null"
                    name="terms_and_conditions"
                  >
                    {{ $t('I accept the terms') }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="confirmation"
                  rules="required"
                >
                  <b-form-checkbox
                    id="confirmation"
                    v-model="form.confirmation"
                    :state="errors.length > 0 ? false:null"
                    name="confirmation"
                  >
                    {{ $t('I understand that I will not share any sensitive information on NHP or with other users') }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="d-flex justify-content-center"
              >
                <b-link
                  class="mr-3"
                  :to="{name: 'home'}"
                >
                  <small>{{ $t('Back') }}</small>
                </b-link>
                <b-button
                  type="submit"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ $t('Verify and register my account') }}
                </b-button>
              </b-form-group>

            </b-form>
          </validation-observer>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-modal-term-privacy-policy"
      :title="$t('Overflowing Content')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Close')"
      :ok-title="$t('Accept')"
    >
      <b-card-text>
        Some text
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BFormRadio,
  BFormRadioGroup,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
  email,
  min,
  confirmed,
  regex,
  phone,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    VBModal,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BFormRadio,
    BFormRadioGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data: () => ({
    form: {
      is_doctor: true,
      hrp_number: '',
      private_mobile: '',
      email: '',
      password: '',
      password_confirmation: '',
      terms_and_conditions: false,
      confirmation: false,
      reason_to_join: '',
      working_place: '',
    },
    required,
    email,
    min,
    confirmed,
    regex,
    phone,
  }),
  methods: {
    validationForm() {
      this.$refs.verificationValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('auth/createUser', { id: this.$route.params.id, data: this.form })
            .then(() => {
              if (this.form.is_doctor) this.$router.push({ name: 'professional_experience' })
              else this.$router.push({ name: 'home' })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Your account has successfully created.'),
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              })
            })
            .catch(e => {
              if (e.response.status === 408) {
                this.$router.push({ name: 'home' }).then(() => { EventBus.$emit('showSignUpModal') })
              } else if (e.response.status === 422) {
                this.$store.dispatch('formErrorResponseResolver', e).then(errors => {
                  this.$refs.verificationValidation.setErrors(errors)
                })
              }
            })
        }
      })
    },
  },
}
</script>
